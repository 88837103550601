<template>
  <div>
    <div
      v-if="!chip"
      class="d-flex"
    >
      <document-picker-list-item-ref
        :item="item"
        :dense="dense"
        :show-icon="true"
      />
      <v-spacer />

      <v-btn
        class="ml-5 mr-5"
        icon
        x-small
        v-if="item.type === 'topics'"
        @click.stop="openTopicInSidePanel"
      >
        <v-icon>{{$icon('i.OpenInNew')}}</v-icon>
      </v-btn>
    </div>
    <v-chip
      :close="!readonly"
      @click:close="$emit('close', item)"
      v-else
    >
      <document-picker-list-item-ref
        :item="item"
        :dense="dense"
        :show-icon="true"
        :class="{'ml-n2': item.type === 'users', 'ml-n4': item.type === 'currency'}"
      />
    </v-chip>
  </div>
</template>
<script>
import sidePanelController from '@/sidePanelController'
export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref')
  },
  methods: {
    openTopicInSidePanel () {
      sidePanelController.displayDocument(this.item.id, 'topics')
    }
  },
  props: {
    chip: Boolean,
    item: {
      type: Object,
      required: true
    },
    readonly: Boolean,
    dense: Boolean
  }
}
</script>
